import React, { useState } from 'react';
import "../components/header.css";

function Header() {
  const [isOpen, setIsOpen] = useState(false);

  const handleMenuClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <nav>
        <div className="logo">
        <a href='/'>
          <img src="https://i.ibb.co/RHBLTXp/logo.png" alt="Logo Image" />
        </a>
        </div>
        <div className={`hamburger ${isOpen ? "toggle" : ""}`} onClick={handleMenuClick}>
          <div className="line1"></div>
          <div className="line2"></div>
          <div className="line3"></div>
        </div>
        <ul className={`nav-links ${isOpen ? "open" : ""}`}>
          <li><a href="/home">Home</a></li>
          <li><a href="/aboutcompany">About Us</a></li>
          <li><a href="/productcollection">Products</a></li>
          <li><a href="/career">Careers</a></li>
          <li><a href="/blogs">Blogs</a></li>
          <li><a href="/contact">Contact Us</a></li>
          <li><a className="join-button" href="https://portal.everestpharmaceuticals.com.np/">E-REPORTING</a></li>
        </ul>
      </nav>
    </div>
  );
}

export default Header;