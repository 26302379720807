import React from "react";
import "../components/Footer.css";

function Footer() {
  return (
    <div>
      <div class="footer">
        <div class="inner-footer">
          <div class="footer-items">
            <h1>Everest Pharmaceuticals</h1>
            <p>
              At Everest Pharma, we aspire to help the community lead a healthy
              life by formulating, developing, commercializing, and delivering
              affordable and accessible medicines that satisfy urgent medical
              needs.
            </p>
          </div>

          <div class="footer-items">
            <h3>Category</h3>
            <div class="border1"></div>
            <ul className="ul">
              <a href="#">
                <li className="li">Anti hypertensive</li>
              </a>
              <a href="#">
                <li className="li">Oral Anti Diabetic</li>
              </a>
              <a href="#">
                <li className="li">Lipid lowering</li>
              </a>
              <a href="#">
                <li className="li">Insulin Therapy</li>
              </a>
              <a href="#">
                <li className="li">Anti Histaminic</li>
              </a>
            </ul>
          </div>

          <div class="footer-items">
            <h3>Quick Links</h3>
            <div class="border1"></div>
            <ul className="ul">
              <a href="/home">
                <li className="li">Home</li>
              </a>
              <a href="/aboutcompany">
                <li className="li">About Us</li>
              </a>
              <a href="productcollection">
                <li className="li">Therapies</li>
              </a>
              <a href="careers">
                <li className="li">Careers</li>
              </a>
              <a href="blogs">
                <li className="li">Blogs</li>
              </a>
              <a href="contact">
                <li className="li">Contact</li>
              </a>
              {/* <a href="#">
                <li className="li">Terms & Condition</li>
              </a> */}
            </ul>
          </div>

          <div className="footer-items">
            <h3>Contact us</h3>
            <div className="border1"></div>
            <ul className="ul">
              <li className="li">
                <i class="fa fa-office" aria-hidden="true"></i>
                <b>Main Office</b>
              </li>
              <li className="li DKM">
                <i className="fa fa-map-marker" aria-hidden="true"></i>2nd
                floor, DKM HOUSE, (Tinkune Marg-82) Kuleshwor Height, Naya
                Basti, Kathmandu, Nepal
              </li>
              <li className="li" id="li_phone">
                <i className="fa fa-phone" aria-hidden="true"></i>
                01-5386780/5378441
              </li>
            </ul>
          </div>
        </div>

        <div className="footer-bottom">
          Copyright &copy; 2023 Everest Pharmaceuticals Pvt. Ltd. | All Right
          Reserved | Developed By JashDigital
        </div>
      </div>
    </div>
  );
}

export default Footer;
