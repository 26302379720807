import React, { useEffect, useState } from "react";
import "../components/ProductTable.css";
import { Link } from "react-router-dom";
import { createClient } from "contentful";

const client = createClient({
  space: "j389y3dfrbxy",
  environment: "master",
  accessToken: "hxOpy1l7J9AMn9pjlGHxB_rd4UTyiTCFUJKvOa4yjV8",
});

function ProductTable({ searchText, selectedAlphabet }) {
  const productsPerPage = 12;
  const [originalProducts, setOriginalProducts] = useState([]); // Store the original unfiltered products
  const [products, setProducts] = useState([]); // Store the filtered products
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    async function fetchProducts() {
      try {
        let response;
        let query = {
          content_type: "productPage",
          order: "fields.title",
          limit: productsPerPage,
          skip: (currentPage - 1) * productsPerPage,
        };

        if (selectedAlphabet !== "All") {
          query["fields.title[regex]"] = `^${selectedAlphabet.toLowerCase()}`;
        }

        response = await client.getEntries(query);

        const filteredResponse = response.items;
        setOriginalProducts(filteredResponse); // Store the original unfiltered products
        setProducts(filteredResponse); // Store the filtered products
        setTotalPages(Math.ceil(filteredResponse.length / productsPerPage));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchProducts();
  }, [currentPage, selectedAlphabet]);

  useEffect(() => {
    async function filterProducts() {
      try {
        let filteredResponse = [...originalProducts]; // Create a copy of originalProducts

        if (searchText) {
          filteredResponse = filteredResponse.filter((product) =>
            product.fields.title.toLowerCase().includes(searchText.toLowerCase())
          );
        }

        setProducts(filteredResponse); // Update the filtered products
        setTotalPages(Math.ceil(filteredResponse.length / productsPerPage));
      } catch (error) {
        console.error("Error filtering data:", error);
      }
    }

    filterProducts();
  }, [searchText, originalProducts, currentPage]);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div>
      <table>
        <thead>
          <tr>
            <th>Name of Product</th>
            <th>Composition</th>
            <th>Packing</th>
            <th>Therapeutic Category</th>
          </tr>
        </thead>
        <tbody>
          {products.map((product) => (
            <tr key={product.sys.id}>
              <td>
                <Link className="product_link" to={`/products/${product.sys.id}`}>
                  {product.fields.title}
                </Link>
              </td>
              <td>{product.fields.composition}</td>
              <td>{product.fields.packing}</td>
              <td>{product.fields.category}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div>
        <button
          className={`button_pagination ${currentPage === 1 ? "disabled" : ""}`}
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
        >
          <i className="fa fa-arrow-left"></i> Previous Page
        </button>
        <button
          className={`button_pagination ${currentPage >= totalPages ? "disabled" : ""}`}
          onClick={handleNextPage}
          disabled={currentPage >= totalPages}
        >
          Next Page <i className="fa fa-arrow-right"></i>
        </button>
      </div>
    </div>
  );
}

export default ProductTable;
